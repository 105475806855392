import React from 'react'
import c from './index.module.scss'

export default function Block(props) {
  const { title, children, style } = props

  // <div className={c.tag} />
  return (
    <div className={c.block} style={style}>
      <div className={c.header}>
        <div className={c.title}>{title}</div>
      </div>
      <div className={c.content}>{children}</div>
    </div>
  )
}
