import React, { useEffect, useState } from 'react';
import c from './index.module.scss'
import clsx from 'classnames';

const Overlay = ({ isActive }) => {
  return (
   <div className={c["overlay-container"]}>
      <div id={'overlay'} className={clsx(c.overlay, isActive ? c.active : c.inactive )}>
      {/* 你可以在这里添加覆盖层的内容 */}
    </div>
   </div>
  );
};



export default Overlay;
