import React,{useContext} from 'react'
import { DataContext } from '@/App'
import arrowRightIcon from '@/assets/images/arrow-right-1.svg'
import c from './index.module.scss'
import useLinkClick from '@/hooks/useLinkClick'
import cx from 'classnames'

export default function NewStories({ link, style }) {
  const { handleLinkClick } = useLinkClick()
  const data = useContext(DataContext)

  return (
    <span
      className={cx(c.read,'hoverable')}
      style={style}
      onClick={() => handleLinkClick(link)}
    >
      <span>{data.copyWriter.read}</span>
      <img src={arrowRightIcon} alt="" className={c.arrow}></img>
    </span>
  )
}
