import React, { useContext } from 'react'
import c from './index.module.scss'
import Modal from 'react-modal'
import modalBg from '@/assets/images/modal-bg-mobile.webp'
import { DataContext } from '@/App'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.8)'
  },
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    height: '800px',
    width: '800px',
    zIndex: 10000,
    outline: 'none',
    border: 'none'
  }
}

export default function Footer({ type, onRequestClose, ...props }) {
  const data = useContext(DataContext)
  const title = () => {
    if (type === 'about') return data.meta.about_us.title
    if (type === 'privacy') return data.meta.privacy_policy.title
    return ''
  }

  const content = () => {
    if (type === 'about') return data.meta.about_us.content
    if (type === 'privacy') return data.meta.privacy_policy.content
    return ''
  }
  return (
    <Modal {...props} onRequestClose={onRequestClose} style={customStyles} preventScroll={true}  bodyOpenClassName={c['modal-open']}  >
      <div className={c.popupWrapper} style={{height: type === 'about' ? '425px' : '560px'}} >
        <div className={c.popupContainer}>
          <div className={c.contentWrapper}>
            <div className={c.title}>{title()}</div>
            <pre className={c.content}>{content()}</pre>
          </div>
          <img className={c.picture} src={modalBg} alt=""></img>
          <div className={c.close} onClick={onRequestClose}></div>
        </div>
      </div>
    </Modal>
  )
}
