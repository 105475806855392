import React, { useContext, useState } from 'react'
import useRecommendBook from '@/hooks/useRecommendBook'
import { DataContext } from '@/App'
import Fave from '@/mobile/components/Fave'
import JumpButton from '@/mobile/components/JumpButton'
import c from './index.module.scss'

export default function NewStories() {
  const data = useContext(DataContext)
  const [fave, setFave] = useState(false)
  const { recommendBook } = useRecommendBook()
  const handleLikeClick = () => {
    setFave(false)
    setTimeout(() => {
      setFave(true)
    }, 200)
  }

  return (
    <div className={c.recommendBook}>
      <div className={c.header}>{data.recommend_title}</div>
      <div className={c.imageWrapper}>
        <img src={recommendBook.image} alt="" className={c.mainImage}></img>
        <div className={c.bottomShadow} />
      </div>
      <div className={c.title}>{recommendBook.title}</div>
      <div className={c.desc}>{recommendBook.desc}</div>
      <div className={c.action}>
        <div onClick={handleLikeClick} className={c.like}>
          <Fave fave={fave} />
        </div>
        <JumpButton
          style={{ marginLeft: '24px', boxShadow: '0px 20px 40px 0px #053238' }}
          link={recommendBook.skipPage}
        />
      </div>
    </div>
  )
}
