import React, { useContext } from 'react'
import Block from '../Block/index'
import RecordItem from './components/RecordItem/index'
import c from './index.module.scss'
import useRecordList from '@/hooks/useRecordList'
import { DataContext } from '@/App'

export default function RecordList() {
  const data = useContext(DataContext)
  const limit = 3
  const { displayRecordList, setShowMore, showMore, recordList } =
    useRecordList({
      limit
    })

  const handleClickMore = () => {
    setShowMore(!showMore)
  }

  return (
    <div>
      {displayRecordList.length > 0 && (
        <Block title={data.copyWriter.myRecords}>
          <div className={c.recordListWrapper}>
            <div>
              <div className={c.recordList}>
                {displayRecordList.map((item) => (
                  <RecordItem
                    key={item.title}
                    item={item}
                    className={c.customRecordItem}
                  />
                ))}
              </div>
              {recordList.length > limit && (
                <div onClick={handleClickMore} className={c.more}>
                  <span>{showMore ? 'Put it away' : 'More'}</span>
                </div>
              )}
            </div>
          </div>
        </Block>
      )}
    </div>
  )
}
