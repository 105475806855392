import c from './index.module.scss'
import useLinkClick from '@/hooks/useLinkClick'

export default function BookShelfItem({ item }) {
  const { handleLinkClick } = useLinkClick()
  return (
    <a
      href="#"
      className={c.bookItem}
      onClick={() => handleLinkClick(item.skipPage)}
    >
      <div className={c.imageWrapper}>
        <img className={c.itemImage} src={item.image} alt="bg" />
      </div>
      <div className={c.content}>
        <div className={c.title}>{item.title}</div>
        <div className={c.desc}>{item.desc}</div>
      </div>
    </a>
  )
}
