import React, { useContext } from 'react'
import Header from './components/Header/index'
import RecommendBook from './components/RecommendBook'
import BookShelf from './components/BookShelf'
import RecordList from './components/RecordList'
import NewStories from './components/NewStories'
import Footer from './components/Footer'
import { AudioContext } from '@/App'
import Overlay from '@/mobile/components/Overlay'
// import cx from 'classnames'
import c from './index.module.scss'


export default function MobileHome() {
  const { musicPlay, setMusicPlay, music,overlayActive } = useContext(AudioContext)
  const playMusic = () => {
    if (music.current.paused) {
      music.current.play()
      setMusicPlay(true)
    } else {
      music.current.pause()
      setMusicPlay(false)
    }
  }
  // TODO 头部状态栏兼容
  return (
    <div className={c.global}>
       <Overlay isActive={overlayActive} />
      {/* <div
        onClick={playMusic}
        className={cx(c.music, { [c.play]: musicPlay })}
      ></div> */}
      <Header />
      <div className={c.content}>
        <RecommendBook />
        <NewStories />
        <BookShelf />
        <RecordList />
      </div>
      <Footer />
    </div>
  )
}
