import React from 'react'
import useLinkClick from '@/hooks/useLinkClick'
import arrowRightIcon from '@/assets/images/arrow-right-1.svg'
import c from './index.module.scss'

export default function NewStories({ item }) {
  const { handleLinkClick } = useLinkClick()
  return (
    <div className={c.swiperItem}>
      <div className={c.wrapper}>
        <div className={c.left}>
          <img className={c.coverImage} src={item.image} alt=""></img>
          <a
            href="#"
            className={c.readButton}
            onClick={() => handleLinkClick(item.skipPage)}
          >
            <img src={arrowRightIcon} alt="" className={c.arrow}></img>
          </a>
        </div>
        <div className={c.right}>
          <div className={c.title}>{item.title}</div>
          <div className={c.desc}>{item.desc}</div>
        </div>
      </div>
    </div>
  )
}
