import React, { useCallback, useEffect, useState } from 'react'
import cx from 'classnames'
import c from '../index.module.scss'

export const usePrevNextButtons = (emblaApi) => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollPrev()
  }, [emblaApi])

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return
    emblaApi.scrollNext()
  }, [emblaApi])

  const onSelect = useCallback((emblaApi) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev())
    setNextBtnDisabled(!emblaApi.canScrollNext())
  }, [])

  useEffect(() => {
    if (!emblaApi) return

    onSelect(emblaApi)
    emblaApi.on('reInit', onSelect).on('select', onSelect)
  }, [emblaApi, onSelect])

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  }
}

export const PrevButton = (props) => {
  const { children, ...restProps } = props

  return (
    <button
      className="embla__button embla__button--prev"
      type="button"
      {...restProps}
    >
      <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
        <title>箭头左</title>
        <g
          id="箭头左"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="【图标】/Line/右箭头"
            transform="translate(8, 8) scale(-1, 1) translate(-8, -8)"
          >
            <rect
              id="Background"
              fill="#D8D8D8"
              opacity="0"
              x="0"
              y="0"
              width="16"
              height="16"
            ></rect>
            <path
              d="M5.12129599,1.81848304 C5.39209977,1.53162598 5.83554131,1.50557207 6.13734757,1.74936823 L6.18151696,1.78796266 L11.9248719,7.20990435 C12.3599384,7.62062342 12.379676,8.30626811 11.9689569,8.74133464 L11.9252108,8.78509955 L6.1817516,14.2118157 C5.88067408,14.4962893 5.40599115,14.4828291 5.12151759,14.1817516 C4.8505904,13.8950111 4.84989788,13.4508054 5.11052836,13.1634113 L5.15158173,13.1215176 L10.5743333,7.99766667 L5.15181637,2.87870401 C4.86495932,2.60790023 4.83890541,2.16445869 5.08270156,1.86265243 L5.12129599,1.81848304 Z"
              id="Shape"
              fill="#08363C"
            ></path>
          </g>
        </g>
      </svg>
      {children}
    </button>
  )
}

export const NextButton = (props) => {
  const { children, ...restProps } = props

  return (
    <button
      className="embla__button embla__button--prev"
      // className={cx(c.embla__button, c.embla__button__next)}
      type="button"
      {...restProps}
    >
      <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1">
        <title>箭头右</title>
        <g
          id="箭头右"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="【图标】/Line/右箭头">
            <rect
              id="Background"
              fill="#D8D8D8"
              opacity="0"
              x="0"
              y="0"
              width="16"
              height="16"
            ></rect>
            <path
              d="M5.12129599,1.81848304 C5.39209977,1.53162598 5.83554131,1.50557207 6.13734757,1.74936823 L6.18151696,1.78796266 L11.9248719,7.20990435 C12.3599384,7.62062342 12.379676,8.30626811 11.9689569,8.74133464 L11.9252108,8.78509955 L6.1817516,14.2118157 C5.88067408,14.4962893 5.40599115,14.4828291 5.12151759,14.1817516 C4.8505904,13.8950111 4.84989788,13.4508054 5.11052836,13.1634113 L5.15158173,13.1215176 L10.5743333,7.99766667 L5.15181637,2.87870401 C4.86495932,2.60790023 4.83890541,2.16445869 5.08270156,1.86265243 L5.12129599,1.81848304 Z"
              id="Shape"
              fill="#08363C"
            ></path>
          </g>
        </g>
      </svg>
      {children}
    </button>
  )
}
