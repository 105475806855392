import React, { useContext } from 'react'
import { DataContext } from '@/App'
import MobileSwiper from '@/mobile/components/MobileSwiper'
import StoryItem from './components/StoryItem'
import Block from '@/mobile/components/Block'
import c from './index.module.scss'

export default function NewStories() {
  const data = useContext(DataContext)
  return (
    <Block title={data.copyWriter.newStory}>
      <div className={c.global}>
        <MobileSwiper swiperList={data.new_stories}>
          {(item) => <StoryItem item={item} />}
        </MobileSwiper>
      </div>
    </Block>
  )
}
