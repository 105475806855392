import React, { useEffect, useContext } from 'react'
import BookShelfItem from '../BookShelfItem/index'
import c from './index.module.scss'
import useBookList from '@/hooks/useBookList'
import { ScreenContext } from '@/App'
import PcSwiper from '@/pc/components/PcSwiper'

export default function BookList() {
  const { bookSwiperList, changeSplitCount } = useBookList({ splitCount: 8 })
  const screen = useContext(ScreenContext)

  useEffect(() => {
    // console.log(screen.mode, 'ooo')
    if (screen.mode === 'PC') {
      changeSplitCount(8)
    } else if (screen.mode === 'Pad') {
      if (screen.width > 951) {
        changeSplitCount(6)
      } else {
        changeSplitCount(4)
      }
    }
  }, [screen.mode, changeSplitCount, screen.width])

  return (
    <div className={c.bookList}>
      <PcSwiper
        swiperList={bookSwiperList}
        SwiperSlideStyle={{ height: '704px' }}
      >
        {(item) => <BookShelfItem key={item.title} item={item} />}
      </PcSwiper>
    </div>
  )
}
