import React from 'react'
import BookShelfItem from '../BookShelfItem/index'
import c from './index.module.scss'
import MobileSwiper from '@/mobile/components/MobileSwiper'
import 'swiper/scss/pagination'
import 'swiper/css'
import 'swiper/scss/autoplay'
import useBookList from '@/hooks/useBookList'

export default function BookList() {
  const { bookSwiperList } = useBookList({ splitCount: 3 })

  return (
    <div className={c.bookList}>
      <MobileSwiper swiperList={bookSwiperList}>
        {(bookListItem) =>
          bookListItem.map((item) => (
            <BookShelfItem key={item.title} item={item} />
          ))
        }
      </MobileSwiper>
    </div>
  )
}
