import React, { useContext } from 'react'
import c from './index.module.scss'
import Modal from 'react-modal'
import modalBg from '@/assets/images/modal-bg-mobile.webp'
import { DataContext } from '@/App'
import { pxToVw } from '@/utils'
import cls from 'classnames'

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)'
  },
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    height: pxToVw(620),
    width: '330px',
    zIndex: 10000,
    padding: '0',
    outline: 'none',
    border: 'none',
    overflow: 'hidden'
  }
}

export default function Footer({ type, onRequestClose, ...props }) {
  const data = useContext(DataContext)
  const title = () => {
    if (type === 'about') return data.meta.about_us.title
    if (type === 'privacy') return data.meta.privacy_policy.title
    return ''
  }

 
  
  const formatContent = (content) => {
    return content.split('\n\n').map((paragraph, index) => {
      // console.log('---> paragraph', paragraph, index)
      // 检测是否为序号标题
      if (/^\d+\.\s/.test(paragraph.split('\n')[0])) {
        const lines = paragraph.split('\n');
        const content = lines[1]
        if(content.split(":").length >= 1 && content.split(":")[0].length <= 30 ) {
          return (
            <div key={index} style={{marginBottom:"12px"}}>
              <div className={c["section-title"]}>{lines[0]}</div>
              <div> <span className={c["small-title"]} >{`${content.split(":")[0]}:`}</span> {content.split(":").slice(1).join("")}</div>
            </div>
          );
        }
        return (
          <div key={index} style={{marginBottom:"12px"}}>
            <div className={c["section-title"]}>{lines[0]}</div>
            <div>{lines.slice(1).join('\n')}</div>
          </div>
        );
      }
      // 检测是否为冒号标题
      if (/:$/.test(paragraph.split('\n')[0])) {
        const lines = paragraph.split('\n');
        return (
          <div key={index}>
            <div className={c["small-title"]}>{lines[0]}</div>
            <div>{lines.slice(1).join('\n')}</div>
          </div>
        );
      }
      if(paragraph.split(":").length >= 1 && paragraph.split(":")[0].length <= 30 ) {
        return (
          <div key={index} style={{marginBottom:"12px"}}>
            <div style={{marginBottom:"12px"}}> <span className={c["small-title"]} >{`${paragraph.split(":")[0]}:`}</span> {paragraph.split(":").slice(1).join("")}</div>
          </div>
        );
      }
      return <p key={index} style={{marginBottom:"12px"}} >{paragraph}</p>;
    });
  };

  const content = () => {
    if (type === 'about') return data.meta.about_us.content
    if (type === 'privacy') return formatContent(data.meta.privacy_policy.content)
    return ''
  }
  

  return (
    <Modal {...props} onRequestClose={onRequestClose} style={customStyles} bodyOpenClassName={c['modal-open']} >
      <div className={cls(c.popupWrapper,type==='about'? c['about-us'] : c['privacy-policy'])}>
        <img className={c.banner} src={modalBg} alt=""></img>
        <div className={c.popupContainer}>
          <div className={c.title}>{title()}</div>
          <div className={c.contentWrapper}>{content()}</div>
          <div className={c.close} onClick={onRequestClose}></div>
        </div>
      </div>
    </Modal>
  )
}
