import React from 'react'
import cx from 'classnames'
import c from './index.module.scss'
import arrowRightIcon from '@/assets/images/arrow-right-1.svg'
import useLinkClick from '@/hooks/useLinkClick'

export default function StoryItem({ item }) {
  const { handleLinkClick } = useLinkClick()

  return (
    <div className={cx(c.storyItem)}>
      <img className={c.coverImage} src={item.image} alt=""></img>
      <div className={c.content}>
        <div className={c.title}>{item.title}</div>
        <div className={c.desc}>{item.desc}</div>
        <a
          href="#"
          className={c.readButton}
          onClick={() => handleLinkClick(item.skipPage)}
        >
          <img src={arrowRightIcon} alt="" className={c.arrow}></img>
        </a>
      </div>
    </div>
  )
}
