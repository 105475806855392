import React, { useContext } from 'react'
import { DataContext } from '@/App'
import RecommendBook from './components/RecommendBook/index'
import StoryItem from './components/StoryItem'
import Block from '@/pc/components/Block'
import c from './index.module.scss'

export default function NewStories() {
  const data = useContext(DataContext)

  return (
    <div className={c.content}>
      <RecommendBook />
      <view className={c.right}>
        <Block
          title={data.copyWriter.newStory}
          style={{ width: 'auto', margin: 0 }}
        >
          <div className={c.recordList}>
            {data.new_stories.map((item) => (
              <StoryItem key={item.title} item={item} />
            ))}
          </div>
          <div className={c.newStories}></div>
        </Block>
      </view>
    </div>
  )
}
