import React,{useContext} from 'react'
import { DataContext } from '@/App'
import arrowRightIcon from '@/assets/images/arrow-right-1.svg'
import useLinkClick from '@/hooks/useLinkClick'
import c from './index.module.scss'

export default function NewStories({ link, style }) {
  const { handleLinkClick } = useLinkClick()
  const data = useContext(DataContext)

  return (
    <a
      className={c.read}
      href="#"
      style={style}
      onClick={() => handleLinkClick(link)}
    >
      <span>{data.copyWriter.read}</span>
      <img src={arrowRightIcon} alt="" className={c.arrow}></img>
    </a>
  )
}
