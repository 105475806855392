import React, { useContext, useEffect } from 'react'
import RecordItem from './components/RecordItem/index'
import PcSwiper from '@/pc/components/PcSwiper'
import Block from '@/pc/components/Block'
import c from './index.module.scss'
import { ScreenContext } from '@/App'
import useRecordList from '@/hooks/useRecordList'
import { DataContext } from '@/App'

export default function RecordList() {
  const data = useContext(DataContext)

  const limit = 6
  const { swiperList, changeSplitCount } = useRecordList({
    limit
  })

  const screen = useContext(ScreenContext)

  useEffect(() => {
    // console.log(screen.mode, 'ooo')
    if (screen.mode === 'PC') {
      changeSplitCount(6)
    } else if (screen.mode === 'Pad') {
      if (screen.width > 851) {
        changeSplitCount(4)
      } else {
        changeSplitCount(2)
      }
    }
  }, [screen.mode, changeSplitCount, screen.width])

  // SwiperSlideStyle={{ height: '400px' }}
  return (
    <div>
      {swiperList.length > 0 && (
        <Block title={data.copyWriter.myRecords}>
          <div className={c.recordListWrapper}>
            <PcSwiper swiperList={swiperList}>
              {(item) => <RecordItem key={item.title} item={item} />}
            </PcSwiper>
          </div>
        </Block>
      )}
    </div>
  )
}
