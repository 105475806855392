import React, { useContext, useEffect } from 'react'
import Header from './components/Header/index'
import BookShelf from './components/BookShelf/index'
import NewStores from './components/NewStories/index'
import RecordList from './components/RecordList/index'
import Footer from './components/Footer/index'
import { AudioContext } from '@/App'
import c from './index.module.scss'
// import cx from 'classnames'
import Overlay from './components/Overlay'

export default function PcHome() {
  const { musicPlay, setMusicPlay, music, overlayActive } =
    useContext(AudioContext)

  const playMusic = () => {
    if (music.current.paused) {
      music.current.play()
      setMusicPlay(true)
    } else {
      music.current.pause()
      setMusicPlay(false)
    }
  }

  useEffect(() => {
    const cursor = document.querySelector('.cursor')
    const cursorinner = document.querySelector('.cursor-inner')

    document.addEventListener('mousemove', function (e) {
      var x = e.clientX
      var y = e.clientY
      cursor.style.transform = `translate3d(calc(${e.clientX}px - 50%), calc(${e.clientY}px - 50%), 0)`
    })

    document.addEventListener('mousemove', function (e) {
      var x = e.clientX
      var y = e.clientY
      cursorinner.style.left = x + 'px'
      cursorinner.style.top = y + 'px'
    })

    document.addEventListener('mousedown', function () {
      cursor.classList.add('click')
      cursorinner.classList.add('cursorinnerhover')
    })

    document.addEventListener('mouseup', function () {
      cursor.classList.remove('click')
      cursorinner.classList.remove('cursorinnerhover')
    })

    setTimeout(() => {
      const aList = document.querySelectorAll('a') || []
      const hoverableList = document.querySelectorAll('.hoverable') || []

      const hoverList = [...hoverableList, ...aList]
      hoverList.forEach((item) => {
        item.addEventListener('mouseover', () => {
          cursor.classList.add('hover')
        })
        item.addEventListener('mouseleave', () => {
          cursor.classList.remove('hover')
        })
      })
    }, 1500)

    return () => {
      document.removeEventListener('mousemove', function (e) {})
      document.removeEventListener('mousedown', function () {})
      document.removeEventListener('mouseup', function () {})
      document.removeEventListener('mouseover', function (e) {})
      document.removeEventListener('mouseleave', function (e) {})
      setTimeout(() => {
        const aList = document.querySelectorAll('a') || []
        const hoverableList = document.querySelectorAll('.hoverable') || []
        const hoverList = [...hoverableList, ...aList]
        hoverList.forEach((item) => {
          item.removeEventListener('mouseover', () => {})
          item.removeEventListener('mouseleave', () => {})
        })
      }, 1000)
    }
  }, [])

  return (
    <div className={c.container}>
      <div className="cursor"></div>
      <div className="cursor-inner"></div>
      <Overlay isActive={overlayActive} />
      <Header />
      <div className={c.content}>
        <NewStores />
        <BookShelf />
        <RecordList />
      </div>
      <Footer />
    </div>
  )
}
