import React from 'react'
import c from './index.module.scss'

export default function Block(props) {
  const { title, children } = props
  return (
    <div className={c.block}>
      <div className={c.title}>{title}</div>
      <div className={c.content}>{children}</div>
    </div>
  )
}
