import React, { Component } from 'react'
import c from './index.module.scss'
import cx from 'classnames'

class Fave extends Component {
  render() {
    const { fave } = this.props
    const style = {
      width: 80,
      height: 80
    }
    const animation_class = fave ? c.faveAni : ''
    return (
      <div className={c.faveContainer}>
        <div className={cx(c.twitterFave, animation_class,'hoverable')} style={style}></div>
      </div>
    )
  }
}

export default Fave
