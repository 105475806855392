import React from 'react'
import cx from 'classnames'
import c from './index.module.scss'
import useLinkClick from '@/hooks/useLinkClick'

export default function RecordItem({ item, className }) {
  const { handleLinkClick } = useLinkClick()
  return (
    <a
      href="#"
      onClick={() => handleLinkClick(item.skipPage)}
      className={cx(c.recordItem, className)}
    >
      <img className={c.coverImage} src={item.image} alt=""></img>
      <div className={c.content}>
        <div className={c.title}>{item.title}</div>
        <div className={c.progressive}>
          <div className={c.progressWrapper}>
            <div
              style={{ width: `${item.progressiveRate}%` }}
              className={c.progressInner}
            ></div>
          </div>
          <div className={c.progressFigure}>{item.progressiveRate}%</div>
        </div>
      </div>
    </a>
  )
}
