import { useContext } from 'react'
import { AudioContext } from '@/App'

export default function useLinkClick() {
  const { setOverlayActive } = useContext(AudioContext)

  const handleLinkClick = (url) => {
    setOverlayActive(true)

    setTimeout(() => {
      window.location.href = url
      // setOverlayActive(false)
    }, 600) // 0.6秒后跳转

    setTimeout(() => {
      setOverlayActive(false)
    }, 4000) // 1秒后跳转
  }

  return {
    handleLinkClick
  }
}
