import React from 'react'
import { DotButton, useDotButton } from './components/EmblaCarouselDotButton'
import { usePrevNextButtons } from './components/EmblaCarouselArrowButtons'
import useEmblaCarousel from 'embla-carousel-react'
import arrowRightSvg from '@/assets/images/arrow-right.svg'
import arrowRightDisabledSvg from '@/assets/images/arrow-right-disabled.svg'
import c from './index.module.scss'
import clsx from 'classnames'

const EmblaCarousel = (props) => {
  const { children, swiperList } = props
  const options = {}
  const [emblaRef, emblaApi] = useEmblaCarousel(options)

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick
  } = usePrevNextButtons(emblaApi)
  return (
    <section className={c.embla}>
      <div className={c.embla__viewport} ref={emblaRef}>
        <div className={c.embla__container}>
          {swiperList.map((listItem, index) => (
            <div className={c.embla__slide} key={index}>
              <div className={c.embla__slide__number}>
                {listItem.map((item) => children(item))}
              </div>
            </div>
          ))}
        </div>
      </div>
      {scrollSnaps.length > 1 && (
        <div className={c.embla__controls}>
          <div className={c.embla__buttons}>
            <div
              onClick={!prevBtnDisabled ? onPrevButtonClick : () => {}}
              className={clsx(c.leftButton, c.slideButton,'hoverable')}
            >
              <img
                src={prevBtnDisabled ? arrowRightDisabledSvg : arrowRightSvg}
                alt=""
              ></img>
            </div>
            <div className={c.embla__dots}>
              {scrollSnaps.map((_, index) => (
                <DotButton
                  key={index}
                  onClick={() => onDotButtonClick(index)}
                  className={clsx(
                    c.embla__dot,
                    index === selectedIndex ? c.embla__dot__selected : ''
                  )}
                />
              ))}
            </div>
            <div
              onClick={!nextBtnDisabled ? onNextButtonClick : () => {}}
              className={clsx(c.rightButton, c.slideButton,'hoverable')}
            >
              <img
                src={nextBtnDisabled ? arrowRightDisabledSvg : arrowRightSvg}
                alt=""
              ></img>
            </div>
          </div>
        </div>
      )}
    </section>
  )
}

export default EmblaCarousel
