import { useState, useMemo, useEffect, useContext } from 'react'
import { DataContext } from '@/App'
export default function useRecordList({ limit }) {
  const [recordList, setRecordList] = useState([])
  const [showMore, setShowMore] = useState(false)
  const data = useContext(DataContext)
  const [splitCount, setSplitCount] = useState(limit)

  useEffect(() => {
    const list = []
    for (let key in localStorage) {
      if (key.includes('trackHistory')) {
        const storageValueJson = localStorage[key]
        const storageValue = JSON.parse(storageValueJson)
        const storyName = key.split('_')[key.split('_').length - 1]

        const book = data?.bookshelf_list.find(
          (item) => item.title === storyName
        )
        const pidList = storageValue?.pidList || []
        const finishPidList = storageValue?.finishPidList || []
        const trackTime = storageValue?.trackTime || 0
        const progressiveRate =
          pidList.length === 0
            ? 0
            : Math.floor((finishPidList.length / pidList.length) * 100)

        list.push({
          ...book,
          title: storyName,
          progressiveRate,
          trackTime
        })
      }
    }
    list.sort(function (x, y) {
      return y?.trackTime - x?.trackTime
    })
    // setRecordList([...list, ...list, ...list, ...list, ...list, ...list])
    setRecordList(list)
  }, [data])

  function splitIntoChunks(array, chunkSize = 6) {
    const chunks = []
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize))
    }

    return chunks
  }

  const swiperList = useMemo(() => {
    return splitIntoChunks(recordList, splitCount)
  }, [recordList, splitCount])

  const displayRecordList = useMemo(() => {
    if (showMore) return recordList.slice(0, 15)
    else return recordList.slice(0, splitCount)
  }, [recordList, showMore, splitCount])

  const changeSplitCount = (count) => {
    setSplitCount(count)
  }

  return {
    changeSplitCount,
    swiperList,
    recordList,
    displayRecordList,
    showMore,
    setShowMore
  }
}
