import React, { useEffect, useState, useRef } from 'react'
import PcHome from './pc/index'
import MobileHome from './mobile/index'
import cx from './App.module.scss'

export const DataContext = React.createContext('')
export const AudioContext = React.createContext('')
export const ScreenContext = React.createContext('')
export default function Home() {
  const [isMobile, setIsMobile] = useState(true)
  const [data, setData] = useState(null)
  const [screen, setScreen] = useState({ mode: 'Mobile' })
  const [musicPlay, setMusicPlay] = useState(false)
  const [overlayActive, setOverlayActive] = useState(false)

  const handleResize = () => {
    const currentWidth = window.innerWidth

    if (currentWidth  < 810 ) {
      setIsMobile(true)
      setScreen({ mode: 'Mobile', width: currentWidth })
    } else if (currentWidth >= 810 && currentWidth <= 1200) {
      setIsMobile(false)
      setScreen({ mode: 'Pad', width: currentWidth })
    } else {
      setIsMobile(false)
      setScreen({ mode: 'PC', width: currentWidth })
    }
  }

  const getData = async () => {
    const myHeaders = new Headers({
      'Content-Type': 'application/json',
      Accept: 'application/json'
    })
    fetch('data.json', { headers: myHeaders })
      .then((res) => {
        return res.json()
      })
      .then((_data) => {
        setData(_data)
      })
  }

  const music = useRef(null)
  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    getData()
  }, [])

  const handleMainClick = () => {
    // if (!firstClick) return
    // music.current.play()
    // setMusicPlay(true)
    // setFirstClick(false)
  }

  return (
    <main onClick={handleMainClick} className={cx['container']}>
      {data && (
        <AudioContext.Provider
          value={{
            musicPlay,
            setMusicPlay,
            music,
            overlayActive,
            setOverlayActive
          }}
        >
          {/* <audio
            ref={music}
            id="music"
            src={data.background_music}
            type="audio/mpeg"
            loop
            autoPlay
          ></audio> */}
          <ScreenContext.Provider value={screen}>
            <DataContext.Provider value={data}>
              {isMobile ? <MobileHome /> : <PcHome />}
            </DataContext.Provider>
          </ScreenContext.Provider>
        </AudioContext.Provider>
      )}
    </main>
  )
}
