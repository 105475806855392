import { useState, useMemo, useContext, useEffect } from 'react'
import { DataContext } from '@/App'

export default function useBookList({ splitCount = 8 }) {
  const data = useContext(DataContext)
  const [bookList, setBookList] = useState([])
  const [_splitCount, _setSplitCount] = useState(splitCount)
  useEffect(() => {
    setBookList(data.bookshelf_list)
  }, [data])

  function splitIntoChunks(array, chunkSize = 6) {
    const chunks = []
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize))
    }

    return chunks
  }

  const bookSwiperList = useMemo(() => {
    return splitIntoChunks(bookList, _splitCount)
  }, [bookList, _splitCount])

  const changeSplitCount = (count) => {
    _setSplitCount(count)
  }

  return {
    changeSplitCount,
    bookList,
    bookSwiperList
  }
}
