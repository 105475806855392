import React, { useRef, useState } from 'react'
import cx from 'classnames'
import c from './index.module.scss'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/scss/pagination'
import 'swiper/css'
import 'swiper/scss/autoplay'

export default function MobileSwiper({
  children,
  SwiperSlideStyle,
  swiperList
}) {
  const swiperRef = useRef(null)
  const [swiperIndex, setSwiperIndex] = useState(0)

  return (
    <div className={c.pcSwiper}>
      <Swiper
        className={c.swiper}
        modules={[Navigation, Pagination]}
        slidesPerView="auto"
        centeredSlides={true}
        onSwiper={(swiper) => {
          swiperRef.current = swiper
        }}
        spaceBetween={-30}
        onSlideChange={(swiper) => {
          setSwiperIndex(swiper.activeIndex)
        }}
        pagination={{
          bulletActiveClass: c.bulletActiveClass,
          bulletClass: c.bulletClass,
          horizontalClass: c.horizontalClass
        }}
        scrollbar={{ draggable: true }}
      >
        {swiperList.map((item, index) => {
          return (
            <SwiperSlide style={SwiperSlideStyle} key={index}>
              {children(item)}
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}
