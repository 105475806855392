import React, { useState, useContext, useEffect, useMemo } from 'react'
import c from './index.module.scss'
import cx from 'classnames'
import footerImage from '@/assets/images/footer-image.webp'
import facebookIcon from '@/assets/images/shareIcon/facebook.svg'
import twitterIcon from '@/assets/images/shareIcon/twitter.svg'
import xhsIcon from '@/assets/images/shareIcon/xhs.svg'
import wechatIcon from '@/assets/images/shareIcon/wechat.svg'
import PrivacyModal from './components/PrivacyModal'
import { DataContext } from '@/App'

export default function Footer() {
  const [showModal, setShowModal] = useState(false)
  const [modalType, setModalType] = useState('')

  const showPrivacyPop = () => {
    setShowModal(true)
    setModalType('privacy')
  }

  const aboutUs = () => {
    setShowModal(true)
    setModalType('about')
  }

  const closeModal = () => {
    setShowModal(false)
    setModalType('')
  }
  const [socialPlatformList, setSocialPlatformList] = useState([])
  const data = useContext(DataContext)

  useEffect(() => {
    if (data) {
      if (data.isCn) {
        setSocialPlatformList([
          {
            link: data.social_platform.facebook,
            icon: xhsIcon,
            alt: 'facebook'
          },
          {
            link: data.social_platform.twitter,
            icon: wechatIcon,
            alt: 'twitter'
          }
        ])
      } else {
        setSocialPlatformList([
          {
            link: data.social_platform.facebook,
            icon: facebookIcon,
            alt: 'facebook'
          },
          {
            link: data.social_platform.twitter,
            icon: twitterIcon,
            alt: 'twitter'
          }
        ])
      }
    }
  }, [data])

  const currentYear = useMemo(() => new Date().getFullYear(), [])

  // <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">
  //   备200184号
  // </a>
  return (
    <div className={c.footer}>
      <div className={c.top}>
        <div className={c.links}>
          <div className={c.line}></div>
          <div className={c.aboutUs} onClick={aboutUs}>
            {data.copyWriter.aboutUs}
          </div>
          <div className={c.line}></div>
          <div className={c.privacy} onClick={showPrivacyPop}>
            {data.copyWriter.privacyPolicy}
          </div>
        </div>
        <div className={c.footerImageWrapper}>
          <img className={c.footerImage} src={footerImage} alt=""></img>
          {socialPlatformList.map((item) => (
            <a
              key={item.link}
              href={item.link}
              target="_blank"
              rel="noreferrer"
            >
              <img
                className={cx(c.shareButton, c[item.alt])}
                src={item.icon}
                alt={item.alt}
              />
            </a>
          ))}
        </div>
      </div>
      <div className={c.line}></div>
      <div className={c.bottom}>
        {/* <div className={c.copyright}>
          <span>Copyright © </span>
          {currentYear}
          <span> CGTN </span>
        </div> */}
      </div>
      <PrivacyModal
        type={modalType}
        isOpen={showModal}
        onRequestClose={closeModal}
      />
    </div>
  )
}
