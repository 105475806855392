import c from './index.module.scss'
import JumpButton from '@/pc/components/JumpButton'
export default function BookShelfItem({ item }) {
  return (
    <div className={c.bookShelfItem}>
      <img className={c.itemImage} src={item.image} alt="bg" />
      <div className={c.title}>{item.title}</div>
      <div className={c.desc}>{item.desc}</div>
      <div className={c.read}>
        <JumpButton style={{ margin: '0 auto' }} link={item.skipPage} />
      </div>
    </div>
  )
}
