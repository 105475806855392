import React from 'react'
import c from './index.module.scss'

export default function Header() {
  return (
    <div className={c.header}>
      <div className={c.mainPoster} />
      <div className={c.bottomShadow} />
    </div>
  )
}
