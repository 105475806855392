import React, { useContext } from 'react'
import Block from '../Block/index'
import BookList from './components/BookList/index'
import c from './index.module.scss'
import { DataContext } from '@/App'

export default function BookShelf() {
  const data = useContext(DataContext)
  return (
    <Block title={data.copyWriter.bookshelf}>
      <div className={c.content}>
        <BookList />
      </div>
    </Block>
  )
}
