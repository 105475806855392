import { useState, useContext, useEffect } from 'react'
import { DataContext } from '@/App'

export default function useRecommendBook() {
  const [recommendBook, setRecommendBook] = useState({})
  const data = useContext(DataContext)
  useEffect(() => {
    setRecommendBook(data.recommend)
  }, [data])
  return {
    recommendBook
  }
}
